<template>
  <main class="blank-aside-js"  :class = "(isOpenAside=='true')?'blank-aside':''">
    <header class="text-center">
      <h2>ביטול טעינת פרייפייד</h2>

      <h1>הביטול הצליח</h1>
    </header>

    <div class="page-content text-center my-5">
      <p>הטעינה בוטלה באופן מלא בסכום של 59 ₪</p>

      <button class="btn submit my-3">סגור</button>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    let names = ["a","b","c","d", "e", "f","g", "m", "l", "n"]

    return {
      headers: ["שם העסק", "נייד", "ספק", 'מוצר','תאריך', 'תאריך', 'מחיר לצרכן', 'מחיר עלות'].map((i,idx) => ({text: i, value: names[idx] })),
      table: [{
            a: "1",
            b: "2",
            c: "3",
            d: "4",
            e: "5",
            f: "6",
            g: "7",
            h: "8"
        }]
    }
  }
}
</script>

<style lang="scss" scoped>
    header > h2 {
        text-align: center;
        padding: 16px 0;
    }
    .page-content {
        padding-left: 16px;
        background: white;
    }

    .submit {
      min-width: 410px;
      padding: 8px 16px;
      background: #29abe2;
      border-radius: 6px;
      color: white;
    }
</style>